import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { findIndex } from 'lodash'

import { Layout, PostCard, Pagination } from '../../components/ghost'
import { MetaData } from '../../components/common/meta'
import { createGlobalStyle } from 'styled-components'
import config from '../../utils/siteConfig'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/

class Index extends Component {

    render() {
        const posts = this.props.data.allGhostPost.edges
        // console.log(`---build index page and pageContext: ${pageContext}`)
        // console.log(pageContext)
        // console.log(context)
        const GlobalStyle = createGlobalStyle`
        ${this.props.pageContext.styleIndex}
        `
        // let aboutCreated = false
        let nodeUrl = ``
        return (
            <>
                <MetaData location={this.props.location} />
                <Layout isHome={true}>
                    <div className="container">
                        <section className="post-feed">
                            {posts.map(({ node }) => {
                                //set node.url = `/about/` if it's the latest post with about tag
                                // if ((findIndex(node.tags, tag => tag.name == `about`) >= 0) && (!aboutCreated)) {
                                //     nodeUrl = `/about/`
                                //     aboutCreated = true
                                // } else {
                                //     nodeUrl = `/${node.slug}/`
                                // }
                                nodeUrl = `/${node.slug}/`
                                // console.log('---nodeUrl', nodeUrl)
                                return (
                                    // The tag below includes the markup for each post - components/common/PostCard.js
                                    <PostCard key={node.id} post={node} nodeUrl={nodeUrl}/>
                                )
                            })}
                        </section>
                        <Pagination pageContext={this.props.pageContext} />
                    </div>
                </Layout>
                <GlobalStyle />
            </>
        )    
    }
}

// const Index = ({ data, location, pageContext }) => {
// }

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!, $authors: [String]!, $tags: [String]!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip,
        filter: {authors: {elemMatch: {slug: {in: $authors}}}, tags: {elemMatch: {name: {in: $tags}}}}
    ) {
      edges {
        node {
            slug
            feature_image
            localImage {
              childImageSharp {
                fluid(maxWidth: 3720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            excerpt
            tags {
                name
                id
            }
            authors {
                id
                slug
                name
                profile_image
            }
            ...GhostPostFields
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query GhostPostQuery($limit: Int!, $skip: Int!, $authors: [String]!, $tags: [String]!) {
//     allGhostPost(
//         sort: { order: DESC, fields: [published_at] },
//         limit: $limit,
//         skip: $skip,
//         filter: {authors: {elemMatch: {slug: {in: $authors}}}, tags: {elemMatch: {name: {in: $tags}}}}
//     ) {
//       edges {
//         node {
//           ...GhostPostFields
//         }
//       }
//     }
//   }
// `
